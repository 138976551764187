/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)


//import 'flatpickr/dist/themes/material_blue.css'

//import 'dropzone/dist/dropzone.css'

import './styles/main.scss';
// start the Stimulus application
import './bootstrap.js';

//import './js/mondropzone.js';

import './js/blueimp.js';

//import './js/date.js';

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');